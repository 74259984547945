<template>
  <div class="add-new-courier">
    <title-bar
      title="Add courier"
      submitBtn
      submitBtnTitle="Create courier"
      @submitPressed="submitPressed"
    />
  <courier-form entity="Courier" redirect="/couriers" :requiredPassword="true" :userObject="courier" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import CourierForm from '../Employees/components/EmployeeForm.vue'

export default {
  components: {
    TitleBar,
    CourierForm
  },
  data() {
    return {
      action: null,
      courier: {
        givenName: '',
        familyName: '',
        role: 'ROLE_COURIER',
        telephone: '',
        email: '',
        onOff: false,
        enabled: true,
        file: null,
        password: ''
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  },
}
</script>
